import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import {
  enrichStoryWithGatsbyImages,
  getBlok,
} from "../components/utils/storyblok";
import Seo from "../components/layout/seo";
import {
  getPageSlug,
  prefixUrl,
  suffixTitle,
} from "../components/utils/no-import-commons";
import { supportedLocale } from "../components/context/language-context";

// Statically generate index because Gatsby need it
// somewhat duplicate of templates/page.js
const Index = ({ data }) => {
  const story = data.storyblokEntry;
  if ("content" in story && typeof story.content === "string") {
    story.content = JSON.parse(story.content);
  }

  enrichStoryWithGatsbyImages(story, data?.images?.nodes ?? []);
  const lm = supportedLocale.reduce((o, l) => (o[l] = `/${l}`) && o, {
    default: "/",
  });

  return (
    <Layout lm={lm}>{(story?.content?.content ?? []).map(getBlok)}</Layout>
  );
};

export const Head = ({ data }) => {
  const story = data.storyblokEntry;
  if ("content" in story && typeof story.content === "string") {
    story.content = JSON.parse(story.content);
  }

  const seoInfo = story?.content?.page_information[0];
  seoInfo.url = prefixUrl(getPageSlug(seoInfo.slug, story.lang));
  seoInfo.title = suffixTitle(seoInfo?.title ?? "");

  const extraLangs = supportedLocale;
  const lm = extraLangs.reduce(
    (obj, l) => {
      obj[l] = prefixUrl(`/${l}`);
      return obj;
    },
    { default: prefixUrl("/") }
  );

  return (
    <Seo info={seoInfo} lm={lm}>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          url: "https://www.makeupdesignory.it/",
          logo: "https://a.storyblok.com/f/184608/822x380/1e721143c1/mud-logo.png",
        })}
      </script>
    </Seo>
  );
};

export const query = graphql`
  query Index {
    storyblokEntry(id: { eq: "storyblokentry-223418431-default" }) {
      field_component
      content
      lang
      name
      slug
    }
    images: allFile(
      filter: {
        base: {
          in: [
            "mud-reviews.jpg"
            "modella-pettinata-compressed.jpg"
            "level-1_150kb.jpg"
            "beauty-correttivo-compressed.jpg"
            "dsc09421-compressed.jpg"
            "corsi-special-effects-compressed.jpg"
            "scopri-le-date-compressed.jpg"
            "ragazze-alloggi.jpg"
            "mos-1-compressed.jpg"
            "newsletter-compressed.jpg"
            "2x4.jpg"
            "mosa-1.jpg"
            "4x4.jpg"
            "screenshot-2023-02-03-alle-09-48-56-compressed.jpg"
            "open-day-roma.jpeg"
            "mudnewsletter.jpg"
          ]
        }
      }
    ) {
      nodes {
        base
        publicURL
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
`;

export default Index;
